import {DiscountType} from "../../config/Constants";

const DiscountStats = ({ discountId,  usageDetails, discountType }) => {
    if (!discountId) return null;

    const stats = [
        { label: "Total", value: usageDetails?.totalCapacity },
        { label: "Used", value: usageDetails?.totalUsage },
        { label: "Remaining", value:usageDetails?.totalRemaining }
    ];

    return (
        <div className="row">
            {stats.map((stat, index) => (
                <div key={index} className="form-group mb-3 col-sm-4 col-md-4 mt-3 text-center">
                    <div className="form-group">
                        <label className="text-primary font-w600">
                            {stat.label} {discountType === DiscountType.VOUCHERS ? 'Vouchers' : 'Coupons'} :
                            {stat.value < 0 ? <i className="text-danger la la-infinity "></i> : stat.value}
                        </label>
                    </div>
                </div>
            ))}
        </div>
    );
};
export default DiscountStats;