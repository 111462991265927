import React, { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";

export default function IntroEventQR({item}) {
    const [link, setLink] = useState("dd");
    useEffect(() => {
        if(item){
            qeCodeLinkGenerator(item)
        }
    }, [item]);

    const [options, setOptions] = useState({
        width: 300,
        height: 300,
        type: "svg",
        data: link,
        image: "/favicon.png",
        margin: 10,
        qrOptions: {
            typeNumber: 0,
            mode: "Byte",
            errorCorrectionLevel: "Q",
        },
        imageOptions: {
            hideBackgroundDots: true,
            imageSize: 0.4,
            margin: 20,
            crossOrigin: "anonymous",
        },
        dotsOptions: {
            color: "#222222",
            type: "rounded",
        },
        backgroundOptions: {
            color: "#fff",
        },
        cornersSquareOptions: {
            color: "#222222",
            type: "extra-rounded",
        },
        cornersDotOptions: {
            color: "#222222",
            type: "dot",
        },
    });

    const [fileExt, setFileExt] = useState("svg");
    const qrCodeRef = useRef(null);
    const ref = useRef(null);

    useEffect(() => {
        qrCodeRef.current = new QRCodeStyling(options);
    }, [options, item]);

    useEffect(() => {
        if (ref.current && qrCodeRef.current) {
            qrCodeRef.current.append(ref.current);
        }
    }, [ref, item, options]);

    useEffect(() => {
        if (qrCodeRef.current) {
            qrCodeRef.current.update(options);
        }
    }, [options, item]);



    const onDataChange = (event) => {
        const newData = event.target.value;
        setOptions((prevOptions) => ({ ...prevOptions, data: newData }));
    };

    const onExtensionChange = (event) => {
        const newExtension = event.target.value;
        setFileExt(newExtension);
    };

    const onDownloadClick = () => {
        if (qrCodeRef.current) {
            qrCodeRef.current.download({
                extension: fileExt,
            });
        }
    };

    return (
        <div className="col-12 pb-5">
            <section className={"row"}>
                <div ref={ref} className={"col-12"} />
                <div  className={"col-6 mt-5"}>
                    <input
                        className={"form-control"}
                        value={options.data}
                        onChange={onDataChange}
                        style={styles.inputBox}
                    />
                </div>

                <div  className={"col-6 mt-5 d-flex justify-content-between"}>
                    <select onChange={onExtensionChange} value={fileExt}  className={"form-control w-75"}>
                        <option value="svg">SVG</option>
                        <option value="png">PNG</option>
                        <option value="jpeg">JPEG</option>
                        <option value="webp">WEBP</option>
                    </select>
                    <button type="button" onClick={onDownloadClick}
                            className="btn btn-danger"><i
                        className="flaticon-delete-1"></i> Download
                    </button>
                </div>

            </section>
        </div>
    );


    function qeCodeLinkGenerator(data) {
        if(data){
            const baseUrl = data.country?.toString().includes("BE")
                ? "https://www.knaek.be/intro/"
                : "https://www.knaek.nl/intro/";
            const linkLocal = `${baseUrl}${data.educationName || ""}`;
           setLink(linkLocal)
            setOptions((prevOptions) => ({ ...prevOptions, data: linkLocal }));
        }

    }
};



const styles = {
    inputWrapper: {
        margin: "20px 0",
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        maxWidth: "300px",
    },
    inputBox: {
        flexGrow: 1,
        marginRight: 20,
    },
};
