export const EndKWM = () => {
  return (
    <div className="d-flex flex-column ">
      <p>
        User will receive no more emails as text messages with payment requests.
        Payment status will be changed to 'paid'
      </p>
      <p>However, the subscription will NOT automatically be canceled.</p>
    </div>
  );
};

export const Warning = () => {
  return (
    <div className="d-flex flex-column ">
      <p>
        Attention! You are about to modify critical data. Do you really want to
        update this data? Click 'Update' to proceed
      </p>
    </div>
  );
};

export const EraseUser = () => {
  return (
    <p>
        This will automatically cancel any active subscriptions.
    </p>
  );
};

export const WhiteListUser = () => {
  return (
    <p>
      The user will remain in our database but the email, student email and
      phone number will be altered (a dollar sign ($) will be added in front of
      the data). Thus, it is possible to the user to subscribe again with the
      same credentials.
    </p>
  );
};
export const CanNotBrWhiteListed = () => {
  return (
    <>
      <p>Why would you want to whitelist someone who's not blacklisted?</p>
      <p>Have a cup of coffee, take a 5 min break and when you feel that you can think straight again return to work {":)"}</p>
    </>
  );
};

export const RenderMandateFileContent = ({ src, type }) => {
  if( type == 'application/pdf' )
    return (
      <>
        <iframe src={src} style={{width:"600px",height:"400px"}}></iframe>
      </>
    );
  else
    return (
      <>
        <img src={src} alt="" style={{width:"600px",height:"400px"}}/>
      </>
    );
};