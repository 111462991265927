import React from "react";

const DiscountOption = ({ type, selectedType, onSelect, title, description }) => (
    <section className="form-group mb-3 col-sm-4 col-md-4 cursor-pointer" onClick={() => onSelect(type)}>
        <div className={`card cursor-pointer ${selectedType === type ? "border-2 border-info" : ""}`}>
            <div className="card-body cursor-pointer">
                <h3 className="text-primary font-w500 cursor-pointer">{title}</h3>
                <label className="text-black font-w500 cursor-pointer">{description}</label>
            </div>
        </div>
    </section>
);

export default DiscountOption;
