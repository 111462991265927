// EraseUserModal.js
import React from "react";
import Modal from "./Modal";
import { EraseUser } from "../constant/modals";

const EraseUserModal = ({ id, ModalTrigger, userData, setUserData }) => {
  return (
    <Modal
      id={id}
      ModalTitle="This will automatically cancel any active subscriptions!"
      ModalBody={<EraseUser />}
      setUserData={setUserData}
      ModalTrigger={ModalTrigger}
      Theme="erase-user"
      endpoint="delete_user"
      userData={userData}
    />
  );
};

export default EraseUserModal;
