import React from "react";

const DiscountFormField = ({
                       label,
                       value,
                       onChange,
                       required = false,
                       type = "text",
                       width, maxDate, placeholder, textArea
                   }) => {
    return (
        <div
            className="form-group mb-3 col-sm-6"
            style={{
                width: width,
            }}
        >
            <label className="text-black font-w500">{label}</label>
            <div className="contact-name">
                {textArea ? <textarea
                    className="form-control w-full"
                    value={value}
                    onChange={onChange}
                    required={required}
                    placeholder={placeholder}
                    aria-multiline={true}
                    rows={6}
                /> : <input
                    className="form-control w-full"
                    value={value}
                    onChange={onChange}
                    required={required}
                    type={type}
                    max={maxDate}
                    placeholder={placeholder}
                />
                }
            </div>
        </div>
    );
};
export default DiscountFormField;