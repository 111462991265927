import React, {useState} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Modal} from "react-bootstrap";
import {addNewVouchersCall} from "../../../Api/ApiCalls";
import {handleError, hasAValue} from "../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import { toastOptions } from "../../../config/Constants";

export default function  AddVoucherModal ({showCouponsModal, setShowCouponsModal, actionId}) {

  const [CouponCodes, setCouponCodes] = useState([]);
  const [duplicatedCodes, setDuplicatedCodes] = useState("");

  function convertToList(items) {
    let itemsString = items.replace(/,/g, " ");
    itemsString = itemsString.replace(/-/g, " ");
    let array2 = itemsString.toString().split(/\s+/);
    setCouponCodes(array2);
  }

  function AddCoupons() {
    if (hasAValue(CouponCodes)
    ) {
        const data = {
          coupones: CouponCodes,
          actionId: actionId,
        };
        addNewVouchersCall(data, actionId)
          .then((success) => {
            toast.success(success.message, toastOptions);
            setShowCouponsModal(false)
          })
          .catch((error) => {
            handleError(error);
            if (hasAValue(error.response?.data?.message)) {
              setDuplicatedCodes(error.response?.data?.message);
            }
          });
    } else {
      let errorMessage = "Missing fields: Vouchers";
      toast.error(errorMessage);
    }
  }
  return (
      <>

          <Modal
              className="modal fade"
              size={"xl"}
              show={showCouponsModal}
              onHide={setShowCouponsModal}
          >
              <div className="" role="document">
                  <div className="">
                      <form>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20"> 
                                    Add Vouchers
                                </h4>
                            </div>
                          <div className="modal-body">
                              <i className="flaticon-cancel-12 close"></i>
                              <div className="add-contact-box">
                                  <div className="add-contact-content">
                                      <div className={"row"}>
                                        
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Vouchers</label>
                                                    <textarea
                                                    rows="23"
                                                    name="title"
                                                    className="form-control mb-3"
                                                    defaultValue={CouponCodes}
                                                    onChange={(val) => {
                                                        convertToList(val.target.value);
                                                    }}
                                                    required
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Vouchers</label>
                                                    <div className="form-group mb-3">
                                                    <div className="card border-0 pb-0">
                                                        <div className="card-body border-top shadow rounded">
                                                        <PerfectScrollbar
                                                            style={{ height: "380px" }}
                                                            id="DZ_W_Todo2"
                                                            className="widget-media overflow-auto height370 ps ps--active-y"
                                                        >
                                                            <ul className="timeline">
                                                            {CouponCodes.map((item, i) => {
                                                                return (
                                                                <li key={i} className={""}>
                                                                    <div className={"timeline-panel"}>
                                                                    <div className="media-body">
                                                                        <h5 className="mb-1">{item}</h5>
                                                                    </div>
                                                                    </div>
                                                                </li>
                                                                );
                                                            })}
                                                            </ul>
                                                        </PerfectScrollbar>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                            <div className="modal-footer">
                                <a className="btn btn-secondary" onClick={() => AddCoupons()}>
                                    Add
                                </a>
                                <a className="btn btn-warning" onClick={() => setShowCouponsModal(false)}>
                                    Discard
                                </a>
                            </div>
                      </form>
                  </div>
              </div>
          </Modal>

      </>
  );

};