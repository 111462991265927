import React, { Fragment, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { updateExpirationDateCodeCall } from "../../../Api/ApiCalls";
import { toast } from "react-toastify";
import { toastOptions } from "../../../config/Constants";
import { handleError, hasAValue } from "../../../utils/SharedFunctions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Alert } from "react-bootstrap";

export default function KnaekIdUpdate() {
  const [knaekIds, setKnaekIds] = useState([]);
  const [expiration_date, setExpiration_date] = useState(undefined);
  const [redeem_before_date, setRedeem_before_date] = useState(undefined);
  const [is_discount_code, setIs_discount_code] = useState(false);
  const [
    selectedDataPickerExpirationDate,
    setSelectedDataPickerExpirationDate,
  ] = useState(new Date());
  const [
    selectedDataPickerRedeemBeforeDate,
    setSelectedDataPickerRedeemBeforeDate,
  ] = useState(new Date());
  const [duplicatedCodes, setDuplicatedCodes] = useState("");

  return (
    <>
      <Fragment>
        <div className="row justify-content-center">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Update existing Knaek-ID’s</h4>
              </div>
              <div className="card-body">
                <section>
                  <div className="row justify-content-center">
                    {duplicatedCodes.length > 0 && (
                      <div className="col-12 mb-2">
                        <Alert variant="light" className="solid alert-square">
                          <strong> {duplicatedCodes} </strong>
                        </Alert>
                      </div>
                    )}
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label">Knaek-ID’s</label>
                        <textarea
                          rows="23"
                          name="title"
                          className="form-control mb-3"
                          defaultValue={knaekIds}
                          onChange={(val) => {
                            convertToList(val.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label">Knaek-ID’s</label>
                        <div className="form-group mb-3">
                          <div className="card border-0 pb-0">
                            <div className="card-body border-top shadow rounded">
                              <PerfectScrollbar
                                style={{ height: "380px" }}
                                id="DZ_W_Todo2"
                                className="widget-media overflow-auto height370 ps ps--active-y"
                              >
                                <ul className="timeline">
                                  {knaekIds.map((item, i) => {
                                    return (
                                      <li key={i} className={""}>
                                        <div className={"timeline-panel"}>
                                          <div className="media-body">
                                            <h5 className="mb-1">{item}</h5>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </PerfectScrollbar>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {/*<div className="col-lg-6 mb-2">*/}
                      {/*    <div className="form-group mb-3">*/}
                      {/*        <p className="mb-1">Expiration date</p>*/}
                      {/*        <DatePicker className="form-control"*/}
                      {/*                    selected={selectedDataPicker}*/}
                      {/*                    onChange={(val) => {*/}
                      {/*                        setSelectedDataPicker(val)*/}
                      {/*                        setExpiration_date(val.toISOString())*/}
                      {/*                    }}/>*/}
                      {/*    </div>*/}
                      {/*</div>*/}

                      <div className="col-lg-4 mb-2">
                        <div className="form-group mb-3">
                          <p className="mb-1">
                            Knaek-ID expiration date (End date unlimited app
                            access)
                          </p>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            selected={selectedDataPickerExpirationDate}
                            onChange={(val) => {
                              setSelectedDataPickerExpirationDate(val);
                              setExpiration_date(val.toISOString());
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 mb-2">
                        <div className="form-group mb-3">
                          <p className="mb-1">
                            Redeem before (marks until when the Knaek-ID kan be
                            activated)
                          </p>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            selected={selectedDataPickerRedeemBeforeDate}
                            onChange={(val) => {
                              setSelectedDataPickerRedeemBeforeDate(val);
                              setRedeem_before_date(val.toISOString());
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 mb-2">
                        <div className="form-group mb-3">
                          <p className="mb-1">
                            Can also be used as a discount code?
                          </p>
                          <input
                            type="checkbox"
                            defaultValue={is_discount_code}
                            checked={is_discount_code}
                            className="form-check-input"
                            onChange={() =>
                              setIs_discount_code(!is_discount_code)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="text-end toolbar toolbar-bottom p-2">
                  <button
                    className="btn btn-secondary sw-btn-next ms-1"
                    onClick={() => updateExpirationDateCode()}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );

  function convertToList(items) {
    let itemsString = items.replace(/,/g, " ");
    itemsString = itemsString.replace(/-/g, " ");
    let array2 = itemsString.toString().split(/\s+/);
    setKnaekIds(array2);
  }

  function updateExpirationDateCode() {
    if (knaekIds.length === 0) {
      return toast.error("At least one code must be provided");
    }

    if (!validateUpdateInputs()) {
      return;
    }

    const data = {
      codes: knaekIds,
      expiration_date,
      redeem_before_date,
      is_discount_code,
    };

    updateExpirationDateCodeCall(data)
        .then((success) => {
          toast.success(success.message, toastOptions);
          // Reset state
          setKnaekIds([]);
          setExpiration_date(undefined);
          setRedeem_before_date(undefined);
          setIs_discount_code(false);
          setSelectedDataPickerExpirationDate(new Date());
          setSelectedDataPickerRedeemBeforeDate(new Date());

          // Clear textarea input
          document.querySelector("textarea[name='title']").value = "";
        })
        .catch((error) => {
          handleError(error);
          if (hasAValue(error.response.data.message)) {
            setDuplicatedCodes(error.response.data.message);
          }
        });
  }

  function validateUpdateInputs() {
    const missingFields = [];

    if (!hasAValue(expiration_date)) missingFields.push("expiration date");
    if (!hasAValue(redeem_before_date)) missingFields.push("redeem before date");
    if (!hasAValue(is_discount_code)) missingFields.push("discount code status");
    if (!hasAValue(knaekIds)) missingFields.push("Knaek IDs");

    if (missingFields.length > 0) {
      const errorMessage = "Missing fields: " + missingFields.join(", ");
      toast.error(errorMessage);
      return false;
    }

    return true;
  }

}
