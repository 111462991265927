import React from "react";
import { Card } from "react-bootstrap";
import {ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Tooltip, Line, Legend} from "recharts";

const DiscountUsageChart = ({ data }) => {
    const dataMax = Math.max(...data.map(item => Math.max(item.usedCoupons, item.boughtVoucher)), 0);

    return (
        <Card className="p-4">
            <h3 className="text-dark font-weight-bold">
                Coupons Used & Vouchers Bought - Last 30 Days
            </h3>
            <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis allowDecimals={false} domain={[0, 'dataMax']} tickCount={dataMax + 1} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="usedCoupons" stroke="#1D4D4F" dot={{ r: 5 }} name="Used Coupons" />
                    <Line type="monotone" dataKey="boughtVoucher" stroke="#DB4CB2" dot={{ r: 5 }} name="Bought Voucher" />
                </LineChart>
            </ResponsiveContainer>
        </Card>
    );
};

export default DiscountUsageChart;
