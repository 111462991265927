import React, {useEffect, useState} from "react";
import {Row, Col, Spinner} from "react-bootstrap";
import StatCard from "./components/StatCard";
import DiscountUsageChart from "./components/DiscountUsageChart";
import {getDiscountUsageStatistics} from "../../../Api/ApiCalls";


const Stats = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);


    useEffect( () => {
         fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await getDiscountUsageStatistics();
            console.log(response);
            setData(response);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
        setLoading(false);
    };

    return (
        <Row className="gy-4">
            {/* Stats Cards */}
            {/*<Col md={4}><StatCard value="60,275" label="Total Users (NL)" /></Col>*/}
            {/*<Col md={4}><StatCard value="42,855" label="Unlimited-mode Users (NL)" /></Col>*/}
            {/*<Col md={4}><StatCard value="17,420" label="Limited-mode Users (NL)" /></Col>*/}

            {/* Chart Section (Takes Full Width) */}
            <Col md={12}>
                {loading ? (
                    <div className="text-center mt-4">
                        <Spinner animation="border" />
                    </div>
                ) : (
                <DiscountUsageChart data={data} />
                )}
            </Col>
        </Row>
    );
};

export default Stats;
