import React, {useState} from "react";
import Modal from "./Modal";
import {ErrorMessage, Field, Form, Formik} from "formik";
import * as yup from "yup";

const CancelSubscriptionModal = ({ id, ModalTrigger, userData, setUserData }) => {
  const [cancelationData, setCancelationData] = useState({
    date: "",
    checked: false,
  });
    const [isFormValid, setIsFormValid] = useState(false);
    const validationSchema = yup.object().shape({
        date: yup
            .date()
            .required("Date is required"),
    });

    const initialValues = {
        date: cancelationData.date,
        noPaymentLink: cancelationData.checked,
    };
    const handleSubmit = (values) => {
        console.log("Form Submitted", values);
    };
    return (

    <Modal
      id={id}
      setUserData={setUserData}
      ModalTitle="Cancel Subscription"
      ModalBody={
          <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
              validateOnMount
              onSubmit={handleSubmit}>
              {({ isValid, values, setFieldValue }) => {
                  if (isValid !== isFormValid) {
                      setIsFormValid(isValid);
                  }
                  // Synchronize Formik values with cancelationData
                  const handleDateChange = (e) => {
                      const newDate = e.target.value;
                      setFieldValue("date", newDate);
                      setCancelationData((prev) => ({
                          ...prev,
                          date: newDate,
                      }));
                  };

                  const handleCheckboxChange = () => {
                      const newChecked = !values.noPaymentLink;
                      setFieldValue("noPaymentLink", newChecked);
                      setCancelationData((prev) => ({
                          ...prev,
                          checked: newChecked,
                      }));
                  };
                  return (

                  <Form id="cancelForm">
                      <div className="mb-3">
                          <label>Cancellation Date</label>
                          <Field
                              name="date"
                              type="date"
                              className="form-control"
                              placeholder="Select a date"
                              value={values.date}
                              onChange={handleDateChange}

                          />
                          <ErrorMessage name="date" component="div" className="text-danger" />
                      </div>
                      <div className="form-check">
                          <Field
                              name="noPaymentLink"
                              type="checkbox"
                              className="form-check-input"
                              checked={values.noPaymentLink}
                              onChange={handleCheckboxChange}

                          />
                          <label className="form-check-label">No payment-link or KWM</label>
                      </div>
                  </Form>
                );
              }}
          </Formik>
      }
      Theme="cancel-sub"
      endpoint="unsubscribe_member_admin"
      userData={userData}
      cancelationData={cancelationData}
      disabled={
        userData?.subscriptions?.[userData?.subscriptions?.length - 1]
          ?.status === "CANCELLED"
          || !isFormValid
      }
      ModalTrigger={ModalTrigger}
    />
  );
};

export default CancelSubscriptionModal;
