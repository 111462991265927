import React,{useContext, useEffect} from 'react';

//Import Components
import { ThemeContext } from "../../config/context/ThemeContext";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import {Card, Col, Row} from "react-bootstrap";
import Tooltip from "react-bootstrap/Tooltip";
import Stats from "./Dashboard/Stats";



const Home = () => {
	const { changeBackground } = useContext(ThemeContext);
	useEffect(() => {
		changeBackground({ value: "light", label: "Light" });
	}, []);
	const data = [
		{ date: "1/1/2025", usedCoupons: 50, boughtVoucher: 450 },
		{ date: "8/1/2025", usedCoupons: 200, boughtVoucher: 200 },
		{ date: "16/1/2025", usedCoupons: 300, boughtVoucher: 350 },
		{ date: "24/1/2025", usedCoupons: 180, boughtVoucher: 250 },
		{ date: "31/1/2025", usedCoupons: 100, boughtVoucher: 400 },
	];
	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="row">
						<div className="col-xl-12">
							<div className="row">
								<div className="col-md-12 col-sm-6">
									<div className="card booking">
										<div className="card-body">
											<div className="booking-status d-flex align-items-center">
												<div className="">
													<h2 className="mb-0 font-w600">Welcome at Knaek admin </h2>

												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						<Stats></Stats>
						</div>

					</div>
				</div>
			</div>
		</>
	)
}
export default Home;
