import React, {useEffect, useState} from "react";
import Select from "react-select";
import {Modal} from "react-bootstrap";
import {toastOptions} from "../../../../config/Constants";
import {
    fetchFilterProductionNotificationCall,
    createCompanybulkFavouritCall
} from "../../../../Api/ApiCalls";
import {hasAValue} from "../../../../utils/SharedFunctions";
import {toast} from "react-toastify";
import 'react-tabs/style/react-tabs.css';
import { ButtonLoading } from "../../../../components/Common/ButtonLoading";


export default function  FavouritModal ({companyId, showModal, setShowModal}) {

    const [filtersLimited, setFiltersLimited] = useState([])
    const [filterLimited, setFilterLimited] = useState(undefined)
    const [filtersAccountOwners, setFiltersAccountOwners] = useState([])
    const [filterAccountOwner, setFilterAccountOwner] = useState(undefined)
    const [filtersCities, setFiltersCities] = useState([])
    const [selectedFiltersCities, setSelectedFiltersCities] = useState([])
    const [country, setCountry] = useState(1)
    const [isSubmiting, setIsSubmiting] = useState()
      
    const CustomClearText = () => "clear all";
    const ClearIndicator = (props) => {
        const {
            children = <CustomClearText/>,
            getStyles,
            innerProps: {ref, ...restInnerProps},
        } = props;
        return (
            <div {...restInnerProps}
                 ref={ref}
                 style={getStyles("clearIndicator", props)}
            >
                <div style={{padding: "0px 5px"}}>{children}</div>
            </div>
        );
    };

    const ClearIndicatorStyles = (base, state) => ({
        ...base,
        cursor: "pointer",
        color: state.isFocused ? "blue" : "black",
    });
    useEffect(() => {
           fetchFilters();
    }, [])

    const limitationTypes = [
        {label: "Limited & Unlimited", value: "limited_unlimited"},
        {label: "Limited", value: "limited"},
        {label: "Unlimited", value: "unlimited"},
        {label: "Limited plus", value: "limited Plus"}
    ]
    return (
        <>
            <Modal
                className="modal fade"
                size={"xl"}
                show={showModal}
            >
                <div className="" role="document">
                    <div className="">
                        <form onSubmit={addFavourite}>
                            <div className="modal-header">
                                <h4 className="modal-title fs-20"> Add Company To Favourite </h4>
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setShowModal(false)}
                                    data-dismiss="modal"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <i className="flaticon-cancel-12 close"></i>
                                <div className="add-contact-box">
                                    <div className="add-contact-content">
                                        <div className={"row"}>

                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Country</label>
                                                    <select
                                                        className="form-control form-control-md"
                                                        defaultValue={hasAValue(country) && country}
                                                        required
                                                        onChange={(val) => {
                                                            setCountry(val.target.value)
                                                        }}
                                                    >
                                                        <option value={1}>Netherlands</option>
                                                        <option value={2}>Belgium</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Cities*</label>
                                                    <Select
                                                        closeMenuOnSelect={false}
                                                        components={{ClearIndicator}}
                                                        styles={{clearIndicator: ClearIndicatorStyles}}
                                                        isMulti
                                                        defaultValue={selectedFiltersCities}
                                                        required
                                                        options={filtersCities}
                                                        onChange={(cities) => {
                                                            setSelectedFiltersCities(cities)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Mode</label>
                                                    <select
                                                        className="form-control form-control-md"
                                                        value={filterLimited}
                                                        onChange={(val) => {
                                                            setFilterLimited(val.target.value ?? undefined);
                                                        }}
                                                    >
                                                        <option value={''}>Choose an option</option>

                                                        {limitationTypes.map((item, i)=>{
                                                            return(
                                                                <option key={item.value} value={item.value}>{item.label}</option>
                                                            )
                                                        })}
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="col-md-6 col-sm-10 mb-2">
                                                <div className="form-group mb-3">
                                                    <label className="text-label">Account Owner</label>
                                                    <select
                                                      className='form-control form-control-md'
                                                      value={filterAccountOwner}
                                                      onChange={val => {
                                                        setFilterAccountOwner(val.target.value ?? undefined);
                                                      }}
                                                    >
                                                    <option value={''}>Choose an option</option>
                                                      {filtersAccountOwners.map((item, i) => {
                                                        return <option key={item.value} value={item.value}>{item.value?.replaceAll('_', ' ')}</option>;
                                                      })}
                                                  </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button className="btn btn-secondary" type="submit" disabled={isSubmiting}>
                                   <ButtonLoading isLoading={isSubmiting} text='Add' />
                                </button>
                                <button className="btn btn-warning" type="button" onClick={() => setShowModal(false)}>
                                    Discard
                                </button>
                            </div>

                        </form>
                    </div>
                </div>
            </Modal>
        </>
    );
    
    function addFavourite(e){ //onSubmit
        e.preventDefault()

        const data = {
            'countryId' : parseInt(country),
            'cityIds' : selectedFiltersCities.map(city => city.value),
            'mode' : filterLimited,
            'accountOwner' : filterAccountOwner 
        }

        setIsSubmiting(true)

        createCompanybulkFavouritCall(data, companyId).then(results => {
            toast.success("The Company were added to the users Favourites !", toastOptions);
            setShowModal(false)
        }).catch(error => {
            toast.error("An Error Occured", toastOptions);
        }).finally(() => setIsSubmiting(false));
    }

    function fetchFilters() {
        fetchFilterProductionNotificationCall().then(results => {
            setFiltersLimited(results.limitationFilters)
            setFiltersAccountOwners(results.accountOwners)
            let cities = results.cities.filter(item => parseInt(item.countryId) === parseInt(country))
            setFiltersCities(cities)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }


};