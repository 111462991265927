import React, { Fragment, useContext, useRef, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import DatePicker from "react-datepicker";
import { handleError, hasAValue } from "../../../utils/SharedFunctions";
import {
  addNewKnaekIdsCall,
  addNewKnaekIdsCallWithCsv,
} from "../../../Api/ApiCalls";
import { toast } from "react-toastify";
import { toastOptions } from "../../../config/Constants";
import { Alert } from "react-bootstrap";

//Import Components

export default function KnaekIdAdd() {
  
  const inputFile = useRef(null);

  const [knaekIds, setKnaekIds] = useState([]);
  const [duplicatedCodes, setDuplicatedCodes] = useState("");
  const [expiration_date, setExpiration_date] = useState(undefined);
  const [redeem_before_date, setRedeem_before_date] = useState(undefined);
  const [is_discount_code, setIs_discount_code] = useState(false);
  const [
    selectedDataPickerExpirationDate,
    setSelectedDataPickerExpirationDate,
  ] = useState(new Date());
  const [
    selectedDataPickerRedeemBeforeDate,
    setSelectedDataPickerRedeemBeforeDate,
  ] = useState(new Date());
  const [csvFile, setCsvFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const validExtensions = ["csv"];
      const fileExtension = file.name.slice(
        ((file.name.lastIndexOf(".") - 1) >>> 0) + 2
      );
      if (validExtensions.includes(fileExtension.toLowerCase())) {
        setCsvFile(file);
      } else {
        toast.error("Invalid file extension. Please select a CSV file.");
      }
    }
  };

  return (
    <>
      <Fragment>
        <div className="row justify-content-center">
          <div className="col-xl-12 col-xxl-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Add Knaek-ID’s</h4>
              </div>
              <div className="card-body">
                <section>
                  <div className={`form-group mb-3`} style={{ width: "300px" }}>
                    <label className="text-black font-w500">
                      Upload CSV File Here (<a href="/knaek_codes.csv" target="_blank"
                                               className={"text-primary"}>Download example</a>)
                    </label>
                    <input
                      type="file"
                      className="form-control"
                      style={{height:"50px"}}
                      accept=".csv"
                      onChange={handleFileChange}
                      ref={inputFile} 
                    />
                  </div>
                  <div className="row justify-content-center">
                    {csvFile && (
                      <div className="col-12 mb-2">
                        <Alert variant="warning" className="solid alert-square">
                          <strong>
                            CSV file is selected. Manual insertions is disabled.
                          </strong>
                        </Alert>
                      </div>
                    )}
                    {duplicatedCodes.length > 0 && (
                      <div className="col-12 mb-2">
                        <Alert variant="light" className="solid alert-square">
                          <strong> {duplicatedCodes} </strong>
                        </Alert>
                      </div>
                    )}
                    {!csvFile && (
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label">Knaek-ID’s</label>
                        <textarea
                          rows="23"
                          name="title"
                          className="form-control mb-3"
                          defaultValue={knaekIds}
                          onChange={(val) => {
                            convertToList(val.target.value);
                          }}
                          required
                        />
                      </div>
                    </div>
                    )}
                    {!csvFile && (
                    <div className="col-lg-6 mb-2">
                      <div className="form-group mb-3">
                        <label className="text-label">Knaek-ID’s</label>
                        <div className="form-group mb-3">
                          <div className="card border-0 pb-0">
                            <div className="card-body border-top shadow rounded">
                              <PerfectScrollbar
                                style={{ height: "380px" }}
                                id="DZ_W_Todo2"
                                className="widget-media overflow-auto height370 ps ps--active-y"
                              >
                                <ul className="timeline">
                                  {knaekIds.map((item, i) => {
                                    return (
                                      <li key={i} className={""}>
                                        <div className={"timeline-panel"}>
                                          <div className="media-body">
                                            <h5 className="mb-1">{item}</h5>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </PerfectScrollbar>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}
                    <div>
                      <div className="col-lg-4 mb-2">
                        <div className="form-group mb-3">
                          <p className="mb-1">
                            Knaek-ID expiration date (End date unlimited app
                            access)
                          </p>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            selected={selectedDataPickerExpirationDate}
                            onChange={(val) => {
                              setSelectedDataPickerExpirationDate(val);
                              setExpiration_date(val);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-lg-4 mb-2">
                        <div className="form-group mb-3">
                          <p className="mb-1">
                            Redeem before (marks until when the Knaek-ID kan be
                            activated)
                          </p>
                          <DatePicker
                            className="form-control"
                            dateFormat="dd-MM-yyyy"
                            selected={selectedDataPickerRedeemBeforeDate}
                            onChange={(val) => {
                              setSelectedDataPickerRedeemBeforeDate(val);
                              setRedeem_before_date(val);
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-lg-4 mb-2">
                        <div className="form-group mb-3">
                          <p className="mb-1">
                            Can also be used as a discount code?
                          </p>
                          <input
                            type="checkbox"
                            defaultValue={is_discount_code}
                            checked={is_discount_code}
                            className="form-check-input"
                            onChange={() =>
                              setIs_discount_code(!is_discount_code)
                            }
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="text-end toolbar toolbar-bottom p-2">
                  <button
                    className="btn btn-secondary sw-btn-next ms-1"
                    onClick={() => addNewKnaekIds()}
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );

  function convertToList(items) {
    let itemsString = items.replace(/,/g, " ");
    itemsString = itemsString.replace(/-/g, " ");
    let array2 = itemsString.toString().split(/\s+/);
    setKnaekIds(array2);
  }

  function addNewKnaekIds() {
    if (!validateInputs()) {
      return;
    }

    if (csvFile) {
      handleCsvFile();
    } else if (knaekIds.length > 0) {
      handleKnaekIds();
    } else {
      toast.error("At least one code must be provided either using CSV or text");
    }
  }

  function validateInputs() {
    const missingFields = [];

    if (!hasAValue(expiration_date)) missingFields.push("expiration date");
    if (!hasAValue(redeem_before_date)) missingFields.push("redeem before date");
    if (!hasAValue(is_discount_code)) missingFields.push("discount code status");
    if (!hasAValue(csvFile) && !hasAValue(knaekIds)) missingFields.push("CSV file or Knaek IDs");

    if (missingFields.length > 0) {
      const errorMessage = "Missing fields: " + missingFields.join(", ");
      toast.error(errorMessage);
      return false;
    }

    return true;
  }

  function handleCsvFile() {
    console.log("pass1");
    const validExtensions = ["csv"];
    const fileExtension = csvFile.name.split('.').pop().toLowerCase();

    if (!validExtensions.includes(fileExtension)) {
      toast.error("Invalid file extension. Please select a CSV file.");
      return;
    }

    const formData = new FormData();
    formData.append("expiration_date", new Date(expiration_date).toISOString());
    formData.append("redeem_before_date", new Date(redeem_before_date).toISOString());
    formData.append("is_discount_code", is_discount_code);
    formData.append("file", csvFile);

    addNewKnaekIdsCallWithCsv(formData)
        .then((success) => {
          toast.success("Knaek Ids received successfully");
          setCsvFile(null);
          inputFile.current.value = "";
        })
        .catch((error) => {
          handleError(error);
          if (hasAValue(error.response.data.message)) {
            setDuplicatedCodes(error.response.data.message);
          }
        });
  }

  function handleKnaekIds() {
    
    const data = {
      expiration_date,
      redeem_before_date,
      is_discount_code,
      codes: knaekIds,
    };

    addNewKnaekIdsCall(data)
        .then((success) => {
          toast.success(success.message, toastOptions);
          setKnaekIds([]);
          document.querySelector("textarea[name='title']").value = "";
        })
        .catch((error) => {
          handleError(error);
          if (hasAValue(error.response.data.message)) {
            setDuplicatedCodes(error.response.data.message);
          }
        });
  }

}
