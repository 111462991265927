import {Modal} from "react-bootstrap";
import DiscountOption from "./DiscountOption";
import {DiscountType} from "../../config/Constants";
import DiscountFormField from "./DiscountFormField";
import RichTextEditor from "../RichTextEditor";
import DiscountStats from "./DiscountStats";
import { MoreInfo } from "../MoreInfo";
import {TooltipMessagesTypes} from "../../enums/tooltipMessagesTypes";
import React, {useRef} from "react";
import DatePicker from "react-datepicker";
import {format} from "date-fns";
import swal from "sweetalert";
import {toast} from "react-toastify";

export default function DiscountModal({
                                          show,
                                          showEdit,
                                          onClose,
                                          formData,
                                          setFormData,
                                          handleSubmit,
                                          onDelete,
                                          discountType,
                                          setDiscountType,
                                          groups,
                                          setFormImageData,
                                          formImageData
                                      }) {
    const qrCodeRef = useRef(null)
    const handleLimitedCoupons = (e) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            amountOfCoupons: e.target.checked ? -1 : 0,
        }));
    };
    return (
        <Modal
            className="modal fade"
            size="xl"
            show={show} onHide={onClose}>
            <div role="document">
                <div>
                    <form>
                        <div className="modal-header">
                            <h4 className="modal-title fs-20"> {showEdit ? "Update Discount" : "Add Discount"} </h4>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={onClose}
                                data-dismiss="modal"
                            ></button>
                        </div>
                        <div className="modal-body">
                            <i className="flaticon-cancel-12 close"></i>
                            <div className="add-contact-box">
                                <div className="add-contact-content">
                                    <div className="row">
                                        <div className="form-group mb-3 col-sm-12 col-md-12">
                                            <div className="row">
                                                <section className="form-group mb-3 col-sm-12 col-md-12 align-content-center">
                                                    <strong className="text-black font-weight-bold">
                                                        Discount type
                                                    </strong>
                                                </section>
                                                <DiscountOption
                                                    type={DiscountType.COUPONS}
                                                    selectedType={discountType}
                                                    onSelect={setDiscountType}
                                                    title="Coupons"
                                                    description="Default for all the app discounts."
                                                />
                                                <DiscountOption
                                                    type={DiscountType.VOUCHERS}
                                                    selectedType={discountType}
                                                    onSelect={setDiscountType}
                                                    title="Vouchers"
                                                    description="Using vouchers type will activate the webshop and the user should go to the webshop to buy voucher."
                                                />
                                                <DiscountOption
                                                    type={DiscountType.EXTERNAL_COUPON}
                                                    selectedType={discountType}
                                                    onSelect={setDiscountType}
                                                    title="External Url"
                                                    description="Default for all the app discounts."
                                                />
                                            </div>

                                        </div>

                                        {formData.localizations.map((localization, index) => (
                                            <div className="w-50" key={index}>
                                                <h4
                                                    style={{
                                                        textAlign: "center",
                                                        padding: "10px",
                                                        fontSize: "25px",
                                                    }}
                                                >
                                                    {localization.lang.includes("nl") ? "Dutch" : "English"}
                                                </h4>
                                                <DiscountFormField
                                                    label="Title"
                                                    placeholder={localization.lang.includes("nl") ? "10% korting op alles" : "10% discount on everything"}
                                                    value={localization.title}
                                                    onChange={(e) => handleInputChange(e, "title", e.target.value, index)}
                                                    required={true}
                                                    width="100%"
                                                />
                                                <DiscountFormField
                                                    label="Description (app)"
                                                    placeholder={localization.lang.includes("nl") ? "Kom langs in de winkel voor servies, tosti-ijzers, alles voor de schoonmaak en nog veel meer." : "Shop tableware, grills, everything for cleaning and lots more."}
                                                    value={localization.description}
                                                    onChange={(e) => handleInputChange(e, "description", e.target.value, index)}
                                                    required={true}
                                                    width="100%"
                                                    textArea={true}
                                                />
                                                {discountType === DiscountType.VOUCHERS && (
                                                    <RichTextEditor
                                                        key={`RTE-${index}`}
                                                        label="Conditions (email)"
                                                        onChange={(value) => handleEditorChange(value, index)}
                                                        initialContent={localization.termsNConditions}
                                                    />
                                                )}

                                            </div>
                                        ))}
                                        <h3 className="text-primary mt-3">Configuration</h3>
                                        { formData.id && (
                                            <DiscountStats discountId={formData.id} usageDetails={formData?.usageDetails} discountType={discountType} />
                                        )}

                                        {/*Average Saving*/}
                                        <div className="form-group mb-3 col-sm-10 col-md-6">
                                            <label className="text-black font-w500">
                                                Average Saving
                                            </label>
                                            <div className="contact-name">
                                                <input
                                                    type={"number"}
                                                    min={0.01}
                                                    className="form-control"
                                                    name="averageSaving"
                                                    required="required"
                                                    value={formData.averageSaving}
                                                    onChange={(e) => handleInputChange(e, "averageSaving", e.target.value)}
                                                />
                                            </div>
                                        </div>

                                        {/*Limitation mode*/}
                                        <div className="form-group mb-3 col-sm-10 col-md-6">
                                            <label className="text-black font-w500">
                                                Limitation mode
                                            </label>
                                            <select
                                                required
                                                value={formData?.usageLimitation}
                                                className="form-control form-control-md"
                                                onChange={(e) =>
                                                    handleInputChange(e, "usageLimitation", e.target.value)
                                                }
                                            >
                                                <option value={"once_day"}>Once a day</option>
                                                <option value={"single_use"}>Single Use</option>
                                            </select>
                                        </div>

                                        {/*Start date*/}
                                        <div className="form-group mb-3 col-sm-10 col-md-6">
                                            <label className="text-black font-w500">
                                                Start date
                                            </label>
                                            <div className="contact-name">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={formData.startDate ? new Date(formData.startDate) : ""}
                                                    onChange={(val) => {
                                                        handleDateChange("startDate",val)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/*End date*/}
                                        <div className="form-group mb-3 col-sm-10 col-md-6">
                                            <label className="text-black font-w500">
                                                End date
                                            </label>
                                            <div className="contact-name">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd-MM-yyyy"
                                                    selected={formData.endDate ? new Date(formData.endDate) : ""}
                                                    onChange={(val) => {
                                                        handleDateChange("endDate", val)
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/*Limited coupons*/}
                                        {discountType !== DiscountType.VOUCHERS && <div className="form-group mb-3 col-sm-10 col-md-6">
                                            <label className="text-black font-w500 d-flex align-items-center">
                                                Limited coupons? (<i
                                                className={"text-danger la la-infinity "}></i> =
                                                <input
                                                    disabled={discountType === DiscountType.EXTERNAL_COUPON}
                                                    type="checkbox"
                                                    name="isUnLimited"
                                                    checked={formData.isUnLimited}
                                                    onChange={(e) => {
                                                        handleInputChange(e, "isUnLimited", e.target.checked);
                                                        handleLimitedCoupons(e);
                                                    }}
                                                    className="form-check-input cursor-pointer limited-coupons-checkbox"
                                                />)
                                            </label>
                                            <div className="input-group mb-3">
                                                { formData.isUnLimited ?
                                                    <div className="disabled-field">
                                                        <i className={"text-danger la la-infinity "}></i>
                                                    </div>
                                                    :
                                                    <input
                                                        type="number"
                                                        min={0}
                                                        disabled={formData.isUnLimited}
                                                        className="form-control"
                                                        name="amountOfCoupons"
                                                        required="required"
                                                        value={formData.amountOfCoupons}
                                                        onChange={(e) => handleInputChange(e, "amountOfCoupons", e.target.value)}
                                                        onBlur={validateAmountOfCoupons}
                                                    />
                                                }
                                            </div>

                                        </div>}

                                        {/*Min purchasable voucher*/}
                                        {discountType === DiscountType.VOUCHERS &&
                                            <>
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label className="text-black font-w500">
                                                        Min purchasable voucher
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className="form-control"
                                                            name="minPurchasableVouchers"
                                                            required="required"
                                                            value={formData?.minPurchasableVouchers}
                                                            onChange={(e) => handleInputChange(e, "minPurchasableVouchers", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                {/*Max purchasable voucher*/}
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label className="text-black font-w500">
                                                        Max purchasable voucher
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className="form-control"
                                                            name="maxPurchasableVouchers"
                                                            required="required"
                                                            value={formData?.maxPurchasableVouchers}
                                                            onChange={(e) => handleInputChange(e, "maxPurchasableVouchers", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                {/*Price*/}
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label className="text-black font-w500">
                                                        Price
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className="form-control"
                                                            name="price"
                                                            required="required"
                                                            value={formData.price}
                                                            onChange={(e) => handleInputChange(e, "price", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                                {/*Transaction Fee*/}
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <label className="text-black font-w500">
                                                        Transaction Fee <span className="text-primary">(Ideal: €0.32 | Bancontact: €0.39)</span>
                                                    </label>
                                                    <div className="input-group mb-3">
                                                        <input
                                                            type="number"
                                                            min={0}
                                                            className="form-control"
                                                            name="transactionFee"
                                                            required="required"
                                                            value={formData.transactionFee}
                                                            onChange={(e) => handleInputChange(e, "transactionFee", e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </>
                                        }

                                        <div className="form-group mb-3 col-sm-10 col-md-6">
                                            <label className="text-black font-w500">
                                                Group
                                            </label>
                                            <select
                                                required
                                                value={formData?.groupId}
                                                className="form-control form-control-md"
                                                onChange={(e) => handleInputChange(e, "groupId", Number(e.target.value))}
                                            >
                                                {groups.map((group) => (
                                                    <option key={group.id} value={group.id}>{group.name}</option>
                                                ))}
                                            </select>
                                        </div>

                                        {/*External Url*/}
                                        {discountType === DiscountType.EXTERNAL_COUPON &&  <div className="form-group mb-3 col-sm-10 col-md-12">
                                            <label className="text-black font-w500">
                                                External Url
                                            </label>
                                            <div className="input-group mb-3">
                                                <input
                                                    className="form-control"
                                                    name="externalUrl"
                                                    required="required"
                                                    placeholder="http://www.example.com"
                                                    value={formData.externalUrl}
                                                    onChange={(e) => handleInputChange(e, "externalUrl", e.target.value)}
                                                />
                                            </div>
                                        </div>}

                                        {/*Can this discount auto start?*/}
                                        {discountType !== DiscountType.VOUCHERS &&
                                            <div className="form-group mb-3 col-sm-10 col-md-8">
                                                <div className="form-group mb-3">
                                                    <input
                                                        type="checkbox"
                                                        name="autoStart"
                                                        checked={formData.autoStart}
                                                        onChange={handleInputChange}
                                                        className="form-check-input cursor-pointer mt-0 me-2"
                                                    />
                                                    <label className="text-black font-w500 mb-0">
                                                        Can this discount auto start? <span className="text-primary">(Auto start when previous coupons have been used)</span>
                                                    </label>
                                                </div>
                                            </div>
                                        }

                                        {/*General Student Discount*/}
                                        <div className={`form-group mb-3 col-sm-10 col-md-4 d-flex justify-content-${(discountType !== DiscountType.VOUCHERS) ? `end` : `start`}`}>
                                            <div className={`form-group mb-3 d-flex align-items-${(discountType !== DiscountType.VOUCHERS) ? `center` : `end pb-1`}`}>
                                                <input
                                                    type="checkbox"
                                                    name="isGeneralStudentDiscount"
                                                    checked={formData.isGeneralStudentDiscount}
                                                    onChange={handleInputChange}
                                                    className="form-check-input cursor-pointer mt-0 me-2"
                                                />
                                                <label className="text-black font-w500 mb-0">
                                                    General Student Discount
                                                </label>
                                                <MoreInfo content={[TooltipMessagesTypes.GENERAL_STUDENT_DISCOUNT]}/>
                                            </div>
                                        </div>

                                        {/*Files*/}
                                        {discountType === DiscountType.COUPONS &&
                                            <>
                                                <h3 className={"text-primary mt-3"}>Files</h3>

                                                {/*QR/Bar Code*/}
                                                <div className="form-group mb-3 col-sm-10 col-md-6">
                                                    <div className="row">
                                                        <div className="form-group mb-3 col-sm-12 col-md-12">
                                                            <label
                                                                className="text-black font-w500 d-flex align-items-center justify-content-between">
                                                                <div className="">
                                                                    QR/Bar code/Etc
                                                                    <MoreInfo
                                                                        content={[TooltipMessagesTypes.DIMENSIONS_250_250, TooltipMessagesTypes.FORMAT_JPG, TooltipMessagesTypes.SIZE_50]}/>
                                                                </div>

                                                                <div className="form-group">
                                                                    <input
                                                                        type="checkbox"
                                                                        name="canGenerateQrCode"
                                                                        checked={formData.canGenerateQrCode}
                                                                        onChange={(e) => handleCanGenerateQrCode(e)}
                                                                        className="form-check-input cursor-pointer mt-0 me-2"
                                                                    />
                                                                    <label className="text-black font-w500 mb-0">
                                                                        Display Knaek-ID as QR code
                                                                    </label>
                                                                </div>

                                                            </label>
                                                            <div className="contact-name">
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    name="qrCode"
                                                                    required={formData?.canGenerateQrCode && "required"}
                                                                    disabled={formData?.canGenerateQrCode}
                                                                    onChange={(e) => handleInputChangeFile(e)}
                                                                    ref={qrCodeRef}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="form-group mb-3 col-sm-12 col-md-12">
                                                            <div>
                                                                <div>
                                                                    <div
                                                                        className="image-container p-1"
                                                                        style={{
                                                                            width: "250px",
                                                                            height: "250px",
                                                                            overflow: "hidden",
                                                                            border: "1px solid #ccc",
                                                                        }}
                                                                    >
                                                                        {
                                                                            formImageData?.qrCodeImage && (
                                                                                <img
                                                                                    id="uploadedImage"
                                                                                    src={formImageData?.qrCodeImage} // Use the preview URL here
                                                                                    alt="Uploaded Image"
                                                                                    style={{width: "100%", height: "100%"}}
                                                                                />
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                        {!showEdit && (
                            <div className="modal-footer">
                                <a className="btn btn-secondary" onClick={handleSubmit}>Add</a>
                                <a className="btn btn-warning" onClick={onClose}>Discard</a>
                            </div>
                        )}

                        {showEdit && (
                            <div className="modal-footer">
                                <a className="btn btn-secondary" onClick={handleSubmit}>Save</a>
                                <a className="btn btn-warning" onClick={onClose}>Cancel</a>
                                <a className="btn btn-danger" onClick={onDelete}>Delete</a>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </Modal>
    );

    function handleInputChange(e, fieldName, value, index) {
        const {name, type, checked} = e.target;
        fieldName = fieldName ? fieldName : name;
        setFormData((prevState) => {
            // Handle localization updates
            if (typeof index !== "undefined" && index >= 0) {
                return {
                    ...prevState,
                    localizations: prevState.localizations.map((loc, i) =>
                        i === index ? { ...loc, [fieldName]: value } : loc
                    ),
                };
            }

            // Determine the new field value based on input type
            const newValue = type === "checkbox" ? checked : type === "number" ? Number(value) : value;

            return { ...prevState, [fieldName]: newValue };
        });
    }

    async function handleEditorChange (value, index) {
        setFormData((prevState) => {
            return {
                ...prevState,
                localizations: prevState.localizations.map((loc, i) =>
                    i === index ? { ...loc, termsNConditions: value } : loc
                ),
            }
        });
    }

    async function handleDateChange(fieldName, value) {

        setFormData(prevFormData => ({
            ...prevFormData,
            [fieldName]: format(value, 'yyyy-MM-dd'),

        }));
    }

    function validateAmountOfCoupons(event) {
        const usedCoupons = formData?.usageDetails?.totalUsage
        const isUnlimited = formData?.isUnLimited
        const amountOfCoupons = Number(event.target.value)
        const prevAmountOfCoupons = formData?.usageDetails?.totalCapacity

        if(!isUnlimited && amountOfCoupons < usedCoupons){
            swal('Not valid Amount', "Total amount of coupons can't be less than used amount of coupons.", 'error').then();
            setFormData(prevFormData => ({
                ...prevFormData,
                amountOfCoupons: prevAmountOfCoupons < 0 ? 0 : prevAmountOfCoupons,
            }));
        }
    }

    function handleCanGenerateQrCode(e){
        handleInputChange(e, "canGenerateQrCode", e.target.checked);
        if(e.target.checked){
            qrCodeRef.current.value = null
            setFormImageData(prevFormData => ({
                ...prevFormData,
                [`qrCodeImage`]: null,
            }));
            setFormData(prevFormData => ({
                ...prevFormData,
                [`qrCodeImage`]: null,

            }));
        }else{
            setFormData(prevFormData => {
                delete prevFormData.qrCodeImage
                return {
                    ...prevFormData,
                }
            });
        }
    }

    async function handleInputChangeFile(e) {
        const {name, files} = e.target;
        const file = files[0];
        if (!file) return;
        if((file.size / (1024*1024)).toFixed(2) > .05)
        {
            toast.error("Size Must be equal or less than 50K")
            return false;
        }
        const validTypes = ["image/png", "image/jpg", "image/jpeg"];
        if (!validTypes.includes(file.type)) {
            toast.error("Image must be in PNG or JPG format");
            return;
        }
        const reader = new FileReader();

        // Function to run once the file is read
        reader.onload = function (upload) {
            const img = new Image();
            img.src = reader.result;

            img.onload = function() {
                if(name === 'qrCode' && ( img.width !== 250 || img.height !== 250 ) ) {
                    toast.error("Image dimensions must be : 250 * 250")
                    return false;
                }
                // Set the state with the new image URL for preview
                setFormData(prevFormData => ({
                    ...prevFormData,
                    [`${name}Image`]: file,
                }));
                setFormImageData(prevFormData => ({
                    ...prevFormData,
                    [`${name}Image`]: upload.target.result,
                }));
            };
        };

        // Read the file as a data URL (base64)
        reader.readAsDataURL(file);

    }
}